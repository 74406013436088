import * as React from "react";
import {Typography} from "@mui/material";

export default function Contactus() {
  return (
    <div>
        <Typography style={{textAlign:"center", color:"#1b597c", fontFamily:"Catamaran", marginTop:"20px"}} variant="h3" gutterBottom>
        Contact Us page
      </Typography>
    
    </div>
  );
}
